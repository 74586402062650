import { createSelector } from '@ngrx/store';

import { getCartTotalQuantity } from '../getCartTotalQuantity';
import { getCartTotalValueAfterDiscounts } from '../getCartTotalValueAfterDiscounts';

export const canShowPaymentSection = createSelector(
    getCartTotalQuantity,
    getCartTotalValueAfterDiscounts,
    (cartTotalQuantity, cartTotalValueAfterDiscounts) => cartTotalQuantity > 0 && cartTotalValueAfterDiscounts > 0,
);
