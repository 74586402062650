import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ConfirmationGuard implements CanActivate, CanActivateChild {
    constructor(
        private _authService: Services.AuthService,
        private _onlineOrdersService: Services.OnlineOrdersService,
        private _routeService: Services.RouteService,
        private _store: Store<OLO.State>,
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const queryParamsOrder = route.queryParams?.order;
        const isLiveViewType = route.data?.isLiveViewType;

        return combineLatest(
            this._routeService.extractOrderConfirmationQueryParams$(),
            this._authService.isAuthorized$(),
            this._store.pipe(
                select(selectors.getMemberState),
                filter((memberState) => !memberState.isDownloading && !memberState.hasFailed),
            ),
        ).pipe(
            take(1),
            switchMap(([queryParams, isAuthorized, member]) => {
                if (!queryParams || isLiveViewType == null || !queryParamsOrder) {
                    console.warn('unable to extract orderId & locationNo or something is wrong with liveview', { queryParams, isLiveViewType });

                    this._routeService.navigate(['/404']);

                    return of(false);
                }
                const cachedOrder = this._onlineOrdersService.getHistoryOrderFromStorage(queryParams.orderId);

                if (isAuthorized) {
                    const memberId = member?.data?.MemberId;
                    if (cachedOrder && cachedOrder.MemberId !== memberId) {
                        this._routeService.navigate(['/404']);

                        return of(false);
                    }
                    this._onlineOrdersService.requestHistoryOrder(queryParams.orderId);

                    return this._store.pipe(
                        select(selectors.getHistoryOrdersState),
                        map((historyOrders) => historyOrders?.orders?.find((order) => order.OrderId === queryParams.orderId)),
                        filter((order) => order && order.isDownloading === false),
                        take(1),
                        map((order) => {
                            /* If current member try check other member order, then api will refuse and return 403 response */
                            if (order.hasFailed) {
                                this._routeService.navigate(['/404']);

                                return false;
                            }

                            return true;
                        }),
                    );
                }

                /* Get cached order for guest member */
                if (!cachedOrder) {
                    this._routeService.navigate(['/membership-only']);

                    return of(false);
                }

                const cachedOrderUrlObj = new Utils.OrderConfirmationUrl(cachedOrder.OrderTypeId, queryParamsOrder);
                if (cachedOrderUrlObj.isLiveViewEnabled && isLiveViewType) {
                    this._onlineOrdersService.requestOrderStatus(queryParams.orderId, true);
                }

                return of(true);
            }),
        );
    }

    public canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(next, state);
    }
}
