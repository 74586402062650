import { createSelector } from '@ngrx/store';

import { getCartTotalQuantity } from '../getCartTotalQuantity';
import { getCartTotalValueAfterDiscounts } from '../getCartTotalValueAfterDiscounts';

export const isCartZeroPrice = createSelector(
    getCartTotalQuantity,
    getCartTotalValueAfterDiscounts,
    (cartTotalQuantity, cartTotalValueAfterDiscounts) => Boolean(cartTotalQuantity) && !cartTotalValueAfterDiscounts,
);
